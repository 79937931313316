import React from 'react';
import Container from 'react-bootstrap/Container';
const classes = require('./assets/scss/application.module.scss');

const img = new URL(
	'./assets/image/index.jpg?width=256px&height=256px',
	import.meta.url,
);

export function Application() {
	return (
		<Container fluid className={classes.top.concat(" ", "d-flex justify-content-center align-items-center user-select-none text-center pe-none p-5")}>
			<div>
				<figure className={classes.figure}>
					<img src={img.href} alt="" />
					<figcaption className='text-white py-3 m-0 h1'>Me gustan los electrones.</figcaption>
				</figure>
			</div>
		</Container>
	);
}