import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import 'modern-normalize/modern-normalize.css';
import 'bootstrap/scss/bootstrap.scss';
import './assets/scss/main.module.scss';
import { Application } from './application';

if (module.hot) module.hot.accept();

createRoot(document.getElementById('root')!)
	.render(<StrictMode children={Application()} />);
