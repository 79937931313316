.top {
  height: 100vh;
}

.figure {
  img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
  }
  figcaption {
    font-family: MilkyNiceClean, sans-serif;
  }
}